import React, { useContext } from "react";
import Slider from "react-slick";
/* import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; */

import "./SliderBlock.scss";
import { withTranslation, Link } from "../../../../i18n";
//import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";

//import Skeleton from "@material-ui/lab/Skeleton";

const SliderBlock = ({ sliderImages, jsonMainpage, i18n: { language } }) => {
    const { slider_timeout, sliders } = sliderImages;
    const settings = {
        dots: true,
        lazyLoad: false,
        infinite: true,
        fade: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: slider_timeout,
        autoplaySpeed: slider_timeout,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false
                }
            }
        ]
    };

    return (
        <div className="slider_home">
            <Slider {...settings}>
                {sliders && sliders.map(({ image, image_adaptive, image_adaptive_ua, image_ua, link }, idx) => (
                    <Link href={link ? link : "/"} key={idx}>
                        <a className="slider_items">
                            {/* <TransitionedBlock> */}
                            <div>
                                <img
                                    src={language === "ru" ? image : image_ua}
                                    className={`desktop_slider_img`}
                                    alt={`PRORAB slider image-${idx}`}
                                />
                                <img
                                    src={language === "ru" ? image_adaptive : image_adaptive_ua}
                                    className={`mobile_slider_image`}
                                    alt={`PRORAB slider image-${idx}`}
                                />
                            </div>
                            {/* </TransitionedBlock> */}
                        </a>
                    </Link>
                ))}
            </Slider>

            <h1 className="main_title" dangerouslySetInnerHTML={{
                __html: `${language === "ua" ? jsonMainpage.title_ua : jsonMainpage.title}`
            }} />


            {/*{language === "ua" ?*/}
            {/*    <h1>*/}
            {/*        {jsonMainpage.title_ua !== '' ?*/}
            {/*            <div className="main_title" dangerouslySetInnerHTML={{*/}
            {/*                __html: `${jsonMainpage.title_ua}`*/}
            {/*            }} />*/}
            {/*            : null*/}
            {/*        }*/}
            {/*    </h1>*/}
            {/*    :*/}
            {/*    <h1>*/}
            {/*        {jsonMainpage.title !== '' ?*/}
            {/*            <div className="main_title" dangerouslySetInnerHTML={{*/}
            {/*                __html: `${jsonMainpage.title}`*/}
            {/*            }} />*/}
            {/*            : null*/}
            {/*        }*/}
            {/*    </h1>*/}
            {/*}*/}
        </div >
    );
};
export default withTranslation("product")(SliderBlock);


